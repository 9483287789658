import {
    RECENTS_CALLS_LIST_START,
    PIE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_MONTH_START,
    RECENTS_CALL_LIST_FILTER,
} from '../../consts/actionTypes';

export const recentsCallsListAction = (payload) => ({
    type: RECENTS_CALLS_LIST_START,
    payload,
});

export const pieDataListAction = (start, end) => ({
    type: PIE_DATA_CALLS_LIST_START,
    start,
    end,
});

export const lineDataListAction = (payload) => ({
    type: LINE_DATA_CALLS_LIST_START,
    payload,
});

export const lineDataListMonthAction = (year, month) => ({
    type: LINE_DATA_CALLS_LIST_MONTH_START,
    year,
    month,
});

export const recentsCallsFilterAction = (start, end) => ({
    type: RECENTS_CALL_LIST_FILTER,
    start,
    end,
});
