import {
    IS_LOADING_BACKDROP,
    SNACKBAR,
    IS_LOADING_VIEW,
    COLORS,
    SEARCH_FILTER_REQUEST,
} from '../../consts/actionTypes';
const initialState = {
    isLoadingBackdrop: false,
    isLoadingView: false,
    doesFilterRequest: false,
    snackBar: {
        open: false,
        msg: '',
        type: '',
    },
    colors: {},
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING_BACKDROP:
            return { ...state, isLoadingBackdrop: action.payload };
        case IS_LOADING_VIEW:
            return { ...state, isLoadingView: action.payload };
        case SNACKBAR:
            return { ...state, snackBar: action.payload };
        case COLORS:
            return { ...state, colors: action.payload.data.colors };
        case SEARCH_FILTER_REQUEST:
            return { ...state, doesFilterRequest: action.payload };
        default:
            return { ...state };
    }
};
export default reducer;
