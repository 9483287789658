/**utilities's actions type */
export const IS_LOADING_BACKDROP = 'IS_LOADING_BACKDROP';
export const IS_LOADING_VIEW = 'IS_LOADING_VIEW';
export const SNACKBAR = 'SNACKBAR';
export const COLORS = 'COLORS';
export const SEARCH_FILTER_REQUEST = 'SEARCH_FILTER_REQUEST';

/**-------------------- */
/**SignIn's action type */
export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_COMPLETE = 'SIGN_IN_COMPLETE';
export const SIGNOUT_START = 'SIGNOUT_START';
export const SIGNOUT_COMPLETE = 'SIGNOUT_COMPLETE';

/**------------------ */
/** call's Action type */
export const RECENTS_CALLS_LIST_START = 'RECENTS_CALLS_LIST_START';
export const RECENTS_CALLS_LIST_COMPLETE = 'RECENTS_CALLS_LIST_COMPLETE';
export const PIE_DATA_CALLS_LIST_START = 'PIE_DATA_CALLS_LIST_START';
export const PIE_DATA_CALLS_LIST_COMPLETE = 'PIE_DATA_CALLS_LIST_COMPLETE';
export const LINE_DATA_CALLS_LIST_START = 'LINE_DATA_CALLS_LIST_START';
export const LINE_DATA_CALLS_LIST_COMPLETE = 'LINE_DATA_CALLS_LIST_COMPLETE';
export const LINE_DATA_CALLS_LIST_MONTH_START = 'LINE_DATA_CALLS_LIST_MONTH_START';
export const RECENTS_CALL_LIST_FILTER = 'RECENTS_CALL_LIST_FILTER';

/**** */
/**User's action Types */
export const USER_LIST_START = 'USER_LIST_START';
export const USER_LIST_COMPLETE = 'USER_LIST_COMPLETE';
export const USER_DATA_START = 'GET_USER_DATA';
export const USER_DATA_COMPLETE = 'USER_DATA_COMPLETE';
/** */
