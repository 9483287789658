import React, { useState } from 'react';
import {
    makeStyles,
    Box,
    Typography,
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Grid,
    Paper,
} from '@material-ui/core/';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import Description from '@material-ui/icons/Description';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AudioPlayerCustom } from '../../../../components';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        padding: 8,
        textAlign: 'center',
        margin: 6,
    },
}));

const RecentCallRow = (props) => {
    const { row, extState, returnNameExt, openModalCall } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row'>
                    {row.cnam} ({row.src})
                </TableCell>
                <TableCell>{row.dst}</TableCell>
                <TableCell>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        {row.disposition === 'BUSY' || row.disposition === 'FAILED' ? (
                            <PhoneMissedIcon style={{ color: '#fd6a68', marginRight: 10 }} />
                        ) : (
                            <PhoneForwardedIcon
                                style={{
                                    color: row.src === extState ? '#37ae59' : '#00affe',
                                    marginRight: 10,
                                }}
                            />
                        )}
                        <Box
                            className={clsx({
                                [classes.badgeAnswer]: row.disposition === 'ANSWERED',
                                [classes.badgeNoAnswer]: row.disposition === 'NO ANSWER',
                                [classes.badgeBusy]:
                                    row.disposition === 'BUSY' || row.disposition === 'FAILED',
                            })}
                        >
                            {row.disposition}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell>
                    {row.calldate_date} - {row.calldate_time}
                </TableCell>
                <TableCell>{row.call_duration}</TableCell>
                <TableCell style={{ display: 'flex', flexDirection: 'row' }}>
                    <AudioPlayerCustom
                    // src={row.recordingfile}
                    />
                    <IconButton
                        onClick={() => openModalCall(true)}
                        style={{
                            backgroundColor: '#438afe',
                            color: 'white',
                            fontSize: 15,
                            marginLeft: 20,
                        }}
                    >
                        <Description style={{ fontSize: 15 }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={7}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box style={{ background: '#e6f7ff', padding: 10 }}>
                            <Grid container spacing={12} alignItems='stretch'>
                                {Object.keys(row).map((item, indexItem) => {
                                    return (
                                        <Paper className={classes.paper} key={indexItem}>
                                            <Box>
                                                <Typography
                                                    variant='caption'
                                                    style={{ fontWeight: '600' }}
                                                >
                                                    {item}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {row[item] === '' ? (
                                                    <Typography
                                                        variant='body2'
                                                        style={{
                                                            color: '#00affe',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        No records
                                                    </Typography>
                                                ) : (
                                                    <Typography variant='body2'>
                                                        {row[item]}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Paper>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
RecentCallRow.propTypes = {
    row: PropTypes.object.isRequired,
    extState: PropTypes.string.isRequired,
    openModalCall: PropTypes.func.isRequired,
};
RecentCallRow.defaultProps = {};
export default RecentCallRow;
