import { combineReducers } from 'redux';
import signInReducer from './signInReducer';
import utilitiesReducer from './utilitiesReducer';
import callReducer from './callReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    signInReducer,
    utilitiesReducer,
    callReducer,
    userReducer,
});

export default rootReducer;
