import React from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { pieDataCallSelector, colorsSelector } from '../../../../Redux/selectors';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const PieChart = () => {
    const dataPieState = useSelector((state) => pieDataCallSelector(state));
    const dataPieWithoutValueCero = dataPieState.filter((element) => {
        return element.value !== 0;
    });
    const colors = useSelector((state) => colorsSelector(state));
    const clrs = dataPieState.reduce(
        (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.id]: currentValue.color,
        }),
        {}
    );

    return (
        <div style={{ flex: 1, height: 300, alignSelf: 'stretch' }}>
            {/* when there is not data found, show custom message */}
            {dataPieWithoutValueCero.length || (
                <Box pt={20}>
                    <Typography variant='h5' color='error' align='center' gutterBottom>
                        NOT_ENOUGH_DATA_FOUND
                    </Typography>
                </Box>
            )}

            {dataPieState.length === 0 ? null : (
                <ResponsivePie
                    data={dataPieWithoutValueCero}
                    margin={{ top: 30, right: 20, bottom: 50, left: 20 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={(d) => clrs[d.id]}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor='#333333'
                    radialLabelsLinkColor={{ from: 'color' }}
                    sliceLabelsSkipAngle={10}
                    sliceLabelsTextColor='#333333'
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 5,
                            translateY: 40,
                            itemsSpacing: 0,
                            itemWidth: 120,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000',
                                    },
                                },
                            ],
                        },
                    ]}
                />
            )}
        </div>
    );
};

export default PieChart;
