import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    root: {
        margin: 10,
        flex: 1,
        display: 'flex',
    },
    content: {
        flex: 1,
        paddingBottom: '16px!important',
    },
});

const SimpleCard = (props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>{children}</CardContent>
        </Card>
    );
};

export default SimpleCard;
