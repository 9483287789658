import React from 'react';
import { makeStyles, Grow, Paper, Box, Typography, Modal } from '@material-ui/core/';
import { AudioPlayerCustom } from '../../../../components';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        width: '40%',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: 30,
    },
    stats: {
        flex: 1,
        alignSelf: 'center',
        color: '#fc5621',
        fontWeight: '500',
    },
}));
const ModalCall = (props) => {
    const classes = useStyles();
    const { recordingfile, transcriptionText, openModal, handleClose } = props;
    return (
        <Modal
            open={openModal}
            onClose={() => handleClose(false)}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grow in={openModal}>
                <Paper className={classes.modal}>
                    <Box>
                        <Typography
                            variant='subtitle1'
                            className={classes.stats}
                            style={{ color: 'black', marginBottom: 15 }}
                        >
                            RECORDING CALLS
                        </Typography>
                        <AudioPlayerCustom
                        //  src={recordingfile}
                        />
                        <Typography
                            variant='subtitle2'
                            className={classes.stats}
                            style={{
                                color: 'black',
                                marginBottom: 15,
                                marginTop: 20,
                                fontWeight: '500',
                            }}
                        >
                            TRANSCRIPTION
                        </Typography>
                        <Typography
                            variant='body1'
                            className={classes.stats}
                            style={{
                                color: 'black',
                                marginBottom: 15,
                                marginTop: 20,
                                fontWeight: '400',
                            }}
                        >
                            {transcriptionText}
                        </Typography>
                    </Box>
                </Paper>
            </Grow>
        </Modal>
    );
};
ModalCall.propTypes = {
    recordingfile: PropTypes.any,
    transcriptionText: PropTypes.string,
    openModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
ModalCall.defaultProps = {
    transcriptionText:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took.',
    recordingfile: '',
};
export default ModalCall;
