import React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    CssBaseline,
    Typography,
    makeStyles,
    fade,
    IconButton,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Divider,
    CircularProgress,
    Menu,
    MenuItem,
    Badge,
    InputBase,
    Box,
    Avatar,
    withStyles,
    useTheme,
} from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Pages from './pages';
import { BackdropProgress, SnackbarAlert } from '../';

//Styles
const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    logo: {
        backgroundImage: `url("/img/logoWhite.png")`,
        backgroundSize: 'contain',
        width: '100%',
        height: '50px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        background: '#fff',
        width: drawerWidth,
        minHeight: '100vh',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        background: '#fff',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: 'auto',
        overflow: 'auto',
        display: 'flex',
        marginTop: 30,
    },
    container: {
        display: 'flex',
        marginTop: theme.spacing(5),
        flex: '1',
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.secondary.main,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonHidden: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 10,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        fontWeight: '400',
        marginRight: 15,
    },
    search: {
        position: 'relative',
        backgroundColor: fade('#ffffff', 1),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        borderRadius: 50,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#438afe',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
        color: '#438afe',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        alignItems: 'center',
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    badge: {
        fontSize: 11,
        fontWeight: 300,
        padding: 0,
        transform: `translate(45%, -50%)`,
        color: theme.palette.white,
        backgroundColor: theme.palette.red,
    },
}));
//End Styles
//Badge Styles
const BadgeStyled = withStyles(() => ({
    badge: {
        backgroundColor: '#54e300',
        color: '#58e802',
        boxShadow: `0 0 0 0.8px #54e300`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);
//End Badge Styles

const View = (props) => {
    const { children, openDrawer, setOpenDrawer, handleLogout, isLoadingViewState, user } = props;
    const classes = useStyles();
    const theme = useTheme();
    // App Bar
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Menu>
    );
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton aria-label='show 4 new mails' color='inherit'>
                    <Badge badgeContent={4} classes={{ badge: classes.badge }}>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label='show 11 new notifications' color='inherit'>
                    <Badge badgeContent={17} classes={{ badge: classes.badge }}>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleLogout}>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='primary-search-account-menu'
                    aria-haspopup='true'
                    color='inherit'
                >
                    <ExitToAppIcon />
                </IconButton>
                <p>Log Out</p>
            </MenuItem>
        </Menu>
    );
    // End App Bar

    return (
        <Box className={classes.root}>
            <CssBaseline />
            <SnackbarAlert />
            <BackdropProgress />
            <AppBar
                position='absolute'
                className={clsx(classes.appBar, openDrawer && classes.appBarShift)}
            >
                <Toolbar>
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={() => setOpenDrawer(true)}
                        className={clsx(classes.menuButton, openDrawer && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box className={classes.search}>
                        <Box className={classes.searchIcon}>
                            <SearchIcon />
                        </Box>
                        <InputBase
                            placeholder='Search…'
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Box>
                    <Box className={classes.grow} />
                    <Box className={classes.sectionDesktop}>
                        {/* <IconButton color='inherit' size='small' style={{ top: 5, marginRight: 5 }}>
                            <Badge
                                badgeContent={4}
                                color='secondary'
                                classes={{ badge: classes.badge }}
                            >
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            color='inherit'
                            size='small'
                            style={{ top: 5, marginRight: 20 }}
                        >
                            <Badge badgeContent={17} classes={{ badge: classes.badge }}>
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <Box
                            onClick={handleProfileMenuOpen}
                            aria-controls={menuId}
                            style={{ cursor: 'pointer' }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <BadgeStyled
                                    overlap='circle'
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant='dot'
                                >
                                    <Avatar
                                        src='img/avatar.png'
                                        style={{
                                            border: '1px solid white',
                                            backgroundColor: 'white',
                                        }}
                                    />
                                </BadgeStyled>
                                <Typography
                                    variant='body2'
                                    style={{ marginLeft: 10, marginRight: 5 }}
                                >
                                    {user.full_name}
                                </Typography>
                                <ArrowDropDownIcon style={{ color: '#FFFFFF' }} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.sectionMobile}>
                        <IconButton
                            aria-label='show more'
                            aria-controls={mobileMenuId}
                            aria-haspopup='true'
                            onClick={handleMobileMenuOpen}
                            color='inherit'
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Drawer
                variant='permanent'
                classes={{
                    paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
                }}
                open={openDrawer}
            >
                <Box
                    className={classes.toolbarIcon}
                    style={{ backgroundColor: theme.palette.secondary.main }}
                >
                    <div className={classes.logo} />
                    <IconButton onClick={() => setOpenDrawer(false)}>
                        <ChevronLeftIcon style={{ color: '#FFFFFF' }} />
                    </IconButton>
                </Box>
                <Divider />
                <List>
                    <Pages />
                </List>
            </Drawer>
            <main className={classes.content}>
                <Box className={classes.appBarSpacer} />
                <Box className={classes.container}>
                    {isLoadingViewState ? <CircularProgress size={75} /> : children}
                </Box>
            </main>
        </Box>
    );
};
View.propTypes = {
    children: PropTypes.element.isRequired,
    openDrawer: PropTypes.bool.isRequired,
    setOpenDrawer: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    isLoadingViewState: PropTypes.bool.isRequired,
};
export default View;
