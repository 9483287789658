import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ED1C26',
        },
        secondary: {
            main: '#565A5D',
        },
        white: '#fff',
        gray: '#cccccc',
        lightGray: '#f2f2f2',
        lightestGray: '#F9F9F9',
        red: '#f02711',
        loginBackground: '#959A9D',
    },

    mixins: {
        toolbar: {
            minHeight: 64,
            background: '#565A5D',
        },
    },
});

export default theme;
