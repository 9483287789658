import React, { forwardRef } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const Pages = () => {
    const routers = [
        {
            icon: <DashboardOutlinedIcon style={{ color: '#438afe' }} />,
            name: 'Dashboard',
            href: '/dashboard',
        },
        {
            icon: <ContactPhoneOutlinedIcon style={{ color: '#438afe' }} />,
            name: 'Calls',
            href: '/calls',
        },
        {
            icon: <ChatOutlinedIcon style={{ color: '#438afe' }} />,
            name: 'Chat',
            href: '/ChatView',
        },
    ];

    return (
        <div>
            {routers.map((router, index) => {
                return (
                    <ListItem button component={CustomRouterLink} to={router.href} key={index}>
                        <ListItemIcon>{router.icon}</ListItemIcon>
                        <ListItemText primary={router.name} style={{ color: 'black' }} />
                    </ListItem>
                );
            })}
        </div>
    );
};
export default Pages;
