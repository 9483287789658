import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    recentsCallListByExtSelector,
    extSelector,
    boxDataCallSelector,
    colorsSelector,
    doesFilterRequestSelector,
    pieDataCallSelector,
} from '../../Redux/selectors';
import {
    recentsCallsListAction,
    pieDataListAction,
    recentsCallsFilterAction,
} from '../../Redux/actions/callAction';
import { getDateInFormat } from '../../utils/';
import { doesFilterRequestAction } from '../../Redux/actions/utilitiesAction';
import View from './view';
import { DateFilter } from '../../components';

const Index = () => {
    const recentsCallListState = useSelector((state) => recentsCallListByExtSelector(state));
    const pieDataCall = useSelector((state) => pieDataCallSelector(state));
    const colors = useSelector((state) => colorsSelector(state));
    const extState = useSelector((state) => extSelector(state));
    const boxCallArray = useSelector((state) => boxDataCallSelector(state));
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(-1);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [openModalCall, setOpenModalCall] = useState(false);
    const isFilterRequested = useSelector((state) => doesFilterRequestSelector(state));

    const countPie = pieDataCall.filter((pie) => pie.value !== 0);

    useEffect(() => {
        const today = new Date();
        const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setSelectedStartDate(firstDateOfMonth);

        if (
            (recentsCallListState && recentsCallListState.length === 0) ||
            isFilterRequested ||
            countPie.length === 0
        ) {
            dispatch(
                pieDataListAction(
                    getDateInFormat(firstDateOfMonth),
                    getDateInFormat(selectedEndDate)
                )
            );
            dispatch(
                recentsCallsFilterAction(
                    getDateInFormat(firstDateOfMonth),
                    getDateInFormat(selectedEndDate)
                )
            );

            if (isFilterRequested) dispatch(doesFilterRequestAction(false));
        }
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : -1);
    };

    const handleOnSearch = () => {
        dispatch(
            pieDataListAction(getDateInFormat(selectedStartDate), getDateInFormat(selectedEndDate))
        );
        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(selectedStartDate),
                getDateInFormat(selectedEndDate)
            )
        );
        dispatch(doesFilterRequestAction(true));
    };

    return (
        <View
            recentsCallListState={recentsCallListState}
            expanded={expanded}
            handleChange={handleChange}
            extState={extState}
            boxCallArray={boxCallArray}
            openModalCall={openModalCall}
            setOpenModalCall={setOpenModalCall}
            dateFilterComp={
                <DateFilter
                    handleOnSearch={handleOnSearch}
                    selectedStartDate={selectedStartDate}
                    setSelectedStartDate={setSelectedStartDate}
                    selectedEndDate={selectedEndDate}
                    setSelectedEndDate={setSelectedEndDate}
                />
            }
        />
    );
};

export default Index;
