import React from 'react';
import {
    makeStyles,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from '@material-ui/core/';
import { ActionCard, SimpleCard } from '../../components';
import PropTypes from 'prop-types';
import { BoxCall, ModalCall, RecentCallRow, LineChart, PieChart } from './components';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        padding: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    stats: {
        flex: 1,
        alignSelf: 'center',
        color: '#fc5621',
        fontWeight: '500',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const View = (props) => {
    const classes = useStyles();
    const {
        recentsCallListState,
        extState,
        boxCallArray,
        openModalCall,
        setOpenModalCall,
        dateFilterComp,
    } = props;

    return (
        <div className={classes.container}>
            {/* Date Filter topbar */}
            {dateFilterComp}

            <Box className={classes.row}>
                {boxCallArray.map((box, index) => {
                    return (
                        <BoxCall
                            key={index}
                            titleBox={box.title}
                            colorValue={box.colorValue}
                            valueCant={box.value}
                            valueprogressBar={box.progressbar}
                        />
                    );
                })}
            </Box>
            <Box className={classes.row}>
                <LineChart />

                <ActionCard title='TOTAL MONTHLY CALLS'>
                    <PieChart />
                </ActionCard>
            </Box>
            <Box className={classes.row}>
                <SimpleCard>
                    <Typography
                        variant='subtitle1'
                        className={classes.stats}
                        style={{ color: 'black', marginBottom: 15 }}
                    >
                        RECENT CALLS
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Call Details</TableCell>
                                    <TableCell>From (Ext)</TableCell>
                                    <TableCell>To (Ext)</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date and Time</TableCell>
                                    <TableCell>Duration</TableCell>
                                    <TableCell>Recording Call</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recentsCallListState &&
                                    recentsCallListState.map((call, index) => {
                                        return (
                                            <RecentCallRow
                                                key={index}
                                                row={call}
                                                extState={extState}
                                                openModalCall={setOpenModalCall}
                                            />
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SimpleCard>
            </Box>
            <ModalCall openModal={openModalCall} handleClose={setOpenModalCall} />
        </div>
    );
};
View.propTypes = {
    recentsCallListState: PropTypes.array.isRequired,
    extState: PropTypes.string.isRequired,
    boxCallArray: PropTypes.array.isRequired,
    openModalCall: PropTypes.bool.isRequired,
    setOpenModalCall: PropTypes.func.isRequired,
};
View.defaultProps = {};
export default View;
