import axios from 'axios';
import { URL_API } from './url';
import {
    getLocalStorage,
    setLocalStorage,
    rmLocalStorage,
    ACCESS_TOKEN,
    ACCESS_NUMBER,
    ORGANIZATION_NAME,
    COLOR_NAME,
    REFRESH_TOKEN,
} from '../utils/';

//Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // token failed, status_code 401
        if (response.data.status_code === 401 && response.config.url !== `${URL_API}auth`)
            return refrestTokenAndReattempRequest(response);
        return response;
    },
    function (error) {
        console.log(error, 'ERROR DID WORK');
    }
);

const refrestTokenAndReattempRequest = (error) => {
    const refreshToken = getLocalStorage(REFRESH_TOKEN);
    const originalRequest = error.config;

    if (error.data.status_code === 401 && originalRequest.url === `${URL_API}refresh_token`) {
        rmLocalStorage(REFRESH_TOKEN);
        rmLocalStorage(ACCESS_TOKEN);
        window.location.href = '/';
        return Promise.reject(error);
    }

    if (error.data.status_code === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return axios
            .post(`${URL_API}refresh_token`, null, {
                headers: { Authorization: `JWT ${refreshToken}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    setLocalStorage(ACCESS_TOKEN, res.data.access_token);
                    originalRequest.headers['Authorization'] = 'JWT ' + res.data.access_token;
                    return axios(originalRequest);
                }
            });
    }
    return Promise.reject(error);
};

export const authCall = (url, data, headers, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers,
    });

export const apiCall = (url, data, token, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers: {
            // 'Authorization': `JWT ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    });

export const formatNumber = (number) => {
    if (Number(number) < 10) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
};
