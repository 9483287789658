const isBrowser = typeof window !== 'undefined';

export const setLocalStorage = (name, token) => {
    isBrowser && window.localStorage.setItem(name, token);
};

export const getLocalStorage = (name) => {
    return isBrowser && window.localStorage.getItem(name);
};

export const rmLocalStorage = (name) => {
    isBrowser && window.localStorage.removeItem(name);
};

export const ACCESS_TOKEN = 'sdt-hdphone-access-token-X';
export const REFRESH_TOKEN = 'sdt-hdphone-refresh-token-X';
export const ACCESS_NUMBER = 'sdt-hdphone-access-number-X';
export const ORGANIZATION_NAME = 'sdt-hdphone-organization-name';
export const COLOR_NAME = 'sdt-hdphone-color-name';
