import { put } from 'redux-saga/effects';
import {
    getLocalStorage,
    ACCESS_TOKEN,
    ACCESS_NUMBER,
    ORGANIZATION_NAME,
    COLOR_NAME,
} from '../../utils/';
import { SIGN_IN_COMPLETE, COLORS } from '../../consts/actionTypes';
import { isLoadingViewAction } from '../actions/utilitiesAction';

export default function* getDataOnPageLoad() {
    yield put(isLoadingViewAction(true));
    const gotToken = yield getLocalStorage(ACCESS_TOKEN);
    const organization = yield getLocalStorage(ORGANIZATION_NAME);
    const accessNumber = yield getLocalStorage(ACCESS_NUMBER);
    const colors = yield getLocalStorage(COLOR_NAME);

    if (!gotToken) return null;

    const data = {
        access_token: gotToken,
        access_number: accessNumber,
        organization: organization,
    };

    yield put({ type: SIGN_IN_COMPLETE, payload: { data } });
    yield put({ type: COLORS, payload: JSON.parse(colors) });
    yield put(isLoadingViewAction(false));
}
