import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteWithLayout, MainLayout } from './components';

import {
    SignIn as SignInView,
    Main as MainView,
    Dashboard as DashboardView,
    Chat as ChatView,
} from './Views';

const routes = () => {
    return (
        <Switch>
            <Redirect exact from='/' to='/sign-in' />
            <Route exact path='/sign-in'>
                <SignInView />
            </Route>
            <RouteWithLayout component={MainView} exact layout={MainLayout} path='/calls' />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path='/dashboard'
            />
            <RouteWithLayout component={ChatView} exact layout={MainLayout} path='/ChatView' />
            <Redirect to='/not-found' />
        </Switch>
    );
};
export default routes;
