/**utilitiesReducer */
export const isLoadingBackdropSelector = (state) => state.utilitiesReducer.isLoadingBackdrop;
export const isLoadingViewSelector = (state) => state.utilitiesReducer.isLoadingView;
export const snackBarSelector = (state) => state.utilitiesReducer.snackBar;
export const colorsSelector = (state) => state.utilitiesReducer.colors;
export const doesFilterRequestSelector = (state) => state.utilitiesReducer.doesFilterRequest;

/**--------- */
/** signInReducer */
export const tokenSelector = (state) => state.signInReducer.access_token;
export const extSelector = (state) => state.signInReducer.access_number;
export const organizationSelector = (state) => state.signInReducer.organization;

/**--------- */
/**  callReducer */
export const recentsCallListByExtSelector = (state) => state.callReducer.recentsCallListByExt;
export const pieDataCallSelector = (state) => state.callReducer.pieDataCall;
export const lineDataCallSelector = (state) => state.callReducer.lineDataCall;
export const boxDataCallSelector = (state) => state.callReducer.boxCalls;
/**----------------- */
/** userReducer */
export const userListSelector = (state) => state.userReducer.userList;
export const userDataSelector = (state) => state.userReducer.userData;
/**--------------- */
