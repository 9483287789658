import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    RECENTS_CALLS_LIST_START,
    RECENTS_CALLS_LIST_COMPLETE,
    PIE_DATA_CALLS_LIST_START,
    PIE_DATA_CALLS_LIST_COMPLETE,
    LINE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_COMPLETE,
    LINE_DATA_CALLS_LIST_MONTH_START,
    RECENTS_CALL_LIST_FILTER,
} from '../../consts/actionTypes';
import { apiCall } from '../../consts/funtions';
import { isLoadingViewAction, snackBarAction } from '../actions/utilitiesAction';
import { RECENTS, PIECHARTDATA, LINECHARTDATA, LINECHARTDATA_MONTH } from '../../consts/url';
import { tokenSelector, organizationSelector } from '../selectors';

function* recentsCallListByExt({ payload }) {
    try {
        yield put(isLoadingViewAction(true));
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `organization_recents/${organization}/${payload}/${offSet}`,
            null,
            tokenState,
            'GET'
        );

        switch (results.status) {
            case 200:
                yield put({
                    type: RECENTS_CALLS_LIST_COMPLETE,
                    payload: results.data.result,
                });
                break;
            case 204:
                yield put({ type: RECENTS_CALLS_LIST_COMPLETE, payload: [] });
                yield put(
                    snackBarAction({
                        open: true,
                        msg: 'Dont have recents call',
                        type: 'info',
                    })
                );
                break;
            default:
                console.log('results', results);
                break;
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingViewAction(false));
}
function* getPieData({ start, end }) {
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${PIECHARTDATA}/${organization}/${start}/${end}/${offSet}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: PIE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: PIE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(
                    snackBarAction({
                        open: true,
                        msg: `Dont have pie info between ${start} and ${end} `,
                        type: 'info',
                    })
                );
                break;
            default:
                console.log('results', results);
                break;
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: errorResponse.data.message,
                type: 'error',
            })
        );
    }
}
function* getLineData({ payload }) {
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${LINECHARTDATA}/${organization}/${payload}/${offSet}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: LINE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: LINE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(
                    snackBarAction({
                        open: true,
                        msg: `Dont have Line info in ${payload}`,
                        type: 'info',
                    })
                );
                break;
            default:
                console.log('results', results);
                break;
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: errorResponse.data.message,
                type: 'error',
            })
        );
    }
}

function* getLineDataMonth({ year, month }) {
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${LINECHARTDATA_MONTH}/${organization}/${year}/${month}/${offSet}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: LINE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: LINE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(
                    snackBarAction({
                        open: true,
                        msg: `Dont have Line info in ${month}/${year}`,
                        type: 'info',
                    })
                );
                break;
            default:
                console.log('results', results);
                break;
        }
    } catch (error) {
        console.log('error', error);
        console.log('error2', error.response);
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: errorResponse.data.message,
                type: 'error',
            })
        );
    }
}

function* recentsCallListFilter({ start, end }) {
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        // fetch the data from the remote
        // destruct the object and get the result data only
        const {
            data: { result },
        } = yield call(
            apiCall,
            `calls_inbound_outbound/${organization}/${start}/${end}/${offSet}`,
            null,
            tokenState,
            'GET'
        );

        // if there is enough data, update the redux store
        // otherwise show error
        if (result) yield put({ type: RECENTS_CALLS_LIST_COMPLETE, payload: result });
        else {
            yield put(
                snackBarAction({
                    open: true,
                    msg: `Don't have recent call from ${start} to ${end}`,
                    type: 'info',
                })
            );
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: errorResponse,
                type: 'error',
            })
        );
    }
}

export default function* calls() {
    yield takeLatest(RECENTS_CALLS_LIST_START, recentsCallListByExt);
    yield takeLatest(PIE_DATA_CALLS_LIST_START, getPieData);
    yield takeLatest(LINE_DATA_CALLS_LIST_START, getLineData);
    yield takeLatest(LINE_DATA_CALLS_LIST_MONTH_START, getLineDataMonth);
    yield takeLatest(RECENTS_CALL_LIST_FILTER, recentsCallListFilter);
}
