import React from 'react';
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Button,
} from '@material-ui/core/';
import { ActionCard, SimpleCard } from '../../components';
import { DatePicker } from '@material-ui/pickers';
import { green, red, blue, grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        padding: theme.spacing(2),
    },
    accordion: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '20%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '20%',
        color: theme.palette.text.secondary,
    },
    details: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
    },
    icon: {
        marginRight: 30,
        marginLeft: 10,
    },
    colorBusy: {
        color: red[500],
    },
    colorAnswered: {
        color: green[500],
    },
    colorAnsweredBack: {
        color: blue[500],
    },
    colorNoAnswered: {
        color: grey[600],
    },
}));

const View = (props) => {
    const classes = useStyles();
    const { recentsCallListState, expanded, handleChange, extState, dateFilterComp } = props;

    return (
        <div className={classes.container}>
            {/* Date Filter topbar */}
            {dateFilterComp}

            {recentsCallListState.map((call, index) => {
                return (
                    <Accordion
                        className={classes.accordion}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        key={index}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {call.src === extState ? (
                                <PhoneForwardedIcon
                                    className={clsx({
                                        [classes.icon]: true,
                                        [classes.colorAnswered]: call.disposition === 'ANSWERED',
                                        [classes.colorNoAnswered]: call.disposition === 'NO ANSWER',
                                        [classes.colorBusy]:
                                            call.disposition === 'BUSY' ||
                                            call.disposition === 'FAILED',
                                    })}
                                />
                            ) : (
                                <PhoneCallbackIcon
                                    className={clsx({
                                        [classes.icon]: true,
                                        [classes.colorAnsweredBack]:
                                            call.disposition === 'ANSWERED',
                                        [classes.colorNoAnswered]: call.disposition === 'NO ANSWER',
                                        [classes.colorBusy]:
                                            call.disposition === 'BUSY' ||
                                            call.disposition === 'FAILED',
                                    })}
                                />
                            )}
                            <Typography className={classes.heading}>
                                {call.calldate_date} - {call.calldate_time}
                            </Typography>
                            <Typography className={classes.heading}>{call.src}</Typography>
                            <Typography className={classes.heading}>{call.dst}</Typography>
                            <Typography className={classes.heading}>{call.disposition}</Typography>
                            <Typography className={classes.heading}>
                                {call.call_duration}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            {Object.keys(call).map((item, indexItem) => {
                                return (
                                    <div key={indexItem}>
                                        <Typography className={classes.heading}>{item}</Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            {call[item]}{' '}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};
View.propTypes = {
    recentsCallListState: PropTypes.array.isRequired,
    expanded: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    extState: PropTypes.string.isRequired,
};
export default View;
