import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Routes from './Routes';
import theme from './consts/theme';
import { ThemeProvider } from '@material-ui/styles';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                    <Routes />
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};
export default App;
