import React, { useState, useEffect } from 'react';
import View from './view';
import { useSelector, useDispatch } from 'react-redux';
import { lineDataCallSelector, colorsSelector } from '../../../../Redux/selectors';
import { lineDataListAction, lineDataListMonthAction } from '../../../../Redux/actions/callAction';
const Index = () => {
    const dispatch = useDispatch();
    const colorsState = useSelector((state) => colorsSelector(state));
    const colors = {
        Inbound: colorsState.inbound,
        Outbound: colorsState.oubound,
    };
    const dataLineState = useSelector((state) => lineDataCallSelector(state));
    const [itemMenu, setItemMenu] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [stringDate, setStringDate] = useState(selectedDate.getFullYear());

    useEffect(() => {
        if (dataLineState.length === 2) {
            if (dataLineState[0].data.length === 0 && dataLineState[1].data.length === 0) {
                setItemMenu('empty');
            } else {
                setItemMenu('');
            }
        } else if (!dataLineState.length) dispatch(lineDataListAction(new Date().getFullYear()));
    }, [dataLineState]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setItemMenu('');
    };
    const handleSelectedItem = (item) => {
        setItemMenu(item);
        setAnchorEl(null);
    };
    const handleOnSearch = () => {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();
        const date = selectedDate.getDate();

        switch (itemMenu) {
            case 'year':
                setItemMenu('loading');
                dispatch(lineDataListAction(year));
                setStringDate(year);
                break;
            case 'month':
                setItemMenu('loading');
                dispatch(lineDataListMonthAction(year, month + 1));
                setStringDate(`${month + 1}/${year}`);
                break;
            default:
                setItemMenu('');
                break;
        }
    };

    return (
        <View
            colors={colors}
            data={dataLineState}
            openMenu={handleClick}
            closeMenu={handleClose}
            anchorEl={anchorEl}
            selectedItemMenu={handleSelectedItem}
            itemMenu={itemMenu}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onSearch={handleOnSearch}
            stringDate={stringDate}
        />
    );
};

export default Index;
