import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction } from '../../Redux/actions/signInAction';
import { isLoadingBackdropAction } from '../../Redux/actions/utilitiesAction';
import { tokenSelector, isLoadingBackdropSelector } from '../../Redux/selectors';
import { userDataAction } from '../../Redux/actions/userAction';
import View from './view';

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenState = useSelector((state) => tokenSelector(state));
    const isLoadingState = useSelector((state) => isLoadingBackdropSelector(state));
    const [signIn, setSignIn] = useState({
        username: '',
        password: '',
        organization: 'sdtphone',
    });
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        if (tokenState) {
            dispatch(userDataAction());
            if (!isLoadingState) {
                history.push('/dashboard');
            }
        }
    }, [tokenState, history, isLoadingState, dispatch]);

    const handleOnChangeSignIn = (e) => {
        setSignIn({
            ...signIn,
            [e.target.name]: e.target.value,
        });
    };
    const handleOnSummit = () => {
        dispatch(isLoadingBackdropAction(true));
        dispatch(signInAction(signIn));
    };
    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            handleOnSummit();
        }
    };
    return (
        <View
            signIn={signIn}
            handleOnChangeSignIn={handleOnChangeSignIn}
            handleOnEnter={handleOnEnter}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            handleOnSummit={handleOnSummit}
        />
    );
};

export default withRouter(Index);
