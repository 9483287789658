import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core/';
import { SimpleCard } from '../../components';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
}));

export const DateFilter = ({
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    handleOnSearch,
    row = true,
}) => {
    const classes = useStyles();

    return (
        <Box className={row ? classes.row : ''}>
            <SimpleCard>
                <DatePicker
                    disableFuture
                    id='start-date'
                    openTo='date'
                    format='dd/MM/yyyy'
                    label='Start date'
                    views={['year', 'month', 'date']}
                    value={selectedStartDate.toISOString()}
                    inputVariant='outlined'
                    onChange={setSelectedStartDate}
                    style={{ marginRight: 20, flex: 1 }}
                />
                <DatePicker
                    disableFuture
                    id='end-date'
                    openTo='date'
                    format='dd/MM/yyyy'
                    label='End date'
                    views={['year', 'month', 'date']}
                    value={selectedEndDate}
                    inputVariant='outlined'
                    onChange={setSelectedEndDate}
                    style={{ marginRight: 20, flex: 1 }}
                    minDate={selectedStartDate}
                />

                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleOnSearch}
                    style={{ flex: 1, maxWidth: 100, padding: '15.5px 0' }}
                >
                    Search
                </Button>
            </SimpleCard>
        </Box>
    );
};

DateFilter.propTypes = {
    selectedStartDate: PropTypes.any.isRequired,
    setSelectedStartDate: PropTypes.func.isRequired,
    handleOnSearch: PropTypes.func.isRequired,
    selectedEndDate: PropTypes.any.isRequired,
    setSelectedEndDate: PropTypes.func.isRequired,
};
